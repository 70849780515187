import React, {Component} from "react";

class Scripture extends Component{
    constructor(){
        super();
        this.data = {
            book: " James",
            chapter: "4",
            verse: "7",
            content: "Submit yourselves therefore to God. Resist the devil, and he will flee from you."
        }  
    }

    updateContent(newData){
        console.log(newData)
        this.setState({content: newData.content});    
    }

    render(){
        return(
            <div>
                <h1 className="header-content ">{this.data.book} {this.data.chapter} : {this.data.verse}</h1>
                <p>{this.data.content}</p>
            </div>
        )
    }
}

export default Scripture