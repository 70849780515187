import './App.css';
import Scripture from './Components/Scripture';
import GenerateButton from './Components/GenerateButton';
import React, {Component, useState} from 'react';
import  axios  from "axios";

// const BOOK_NAMES = [['MAT',28],['MRK',16],['LUK',24],['JHN',21],['ACT',28],
//  ['ROM',16],['1CO',16],['2CO',13], ['GAL',6], ['EPH',6], ['PHP',4], ['COL',4], ['1TH',5],
//  ['2TH',3], ['1TI',6], ['2TI',4], ['TIT',3], ['PHM',1], ['HEB',13],['JAS',5] , ['1PE',5],
//  ['2PE',3], ['1JN',1], ['2JN',1], ['3JN',1], ['Jud',1], ['Rev',22]];
const BOOK_DB = [
['MAT',	28,	[25, 23,17,	25,	48, 34,	29,	34,	38,	42,	30,	50,	58,	36,	39,	28,	27,	35,	30,	34,	46,	46,	39,	51,	46,	75,	66,	20]],
['MRK',	16,	[45, 28,35,	41,	43,	56,	37,	38,	50,	52,	33,	44,	37,	72,	47,	20]			]		,							  
['LUK',	24,	[80, 52,38,	44,	39,	49,	50,	56,	62,	42,	54,	59,	35,	35,	32,	31,	37,	43,	48,	47,	38,	71,	56,	53	]]	,		  
['JHN',	21,	[51, 25,36,	54,	47,	71,	53,	59,	41,	42,	57,	50,	38,	31,	27,	33,	26,	40,	42,	31,	25	]	]		,			  
['ACT',	28,	[26, 47,26,	37,	42,	15,	60,	40,	43,	48,	30,	25,	52,	28,	41,	40,	34,	28,	41,	38,	40,	30,	35,	27,	27,	32,	44,	31]],
['ROM',	16,	[32, 29,31,	25,	21,	23,	25,	39,	33,	21,	36,	21,	14,	26,	33,	25		]	]			,						
['1CO',	16,	[31, 16,23,	21,	13,	20,	40,	13,	27,	33,	34,	31,	13,	40,	58,	24	]]	,										
['2CO',	13,	[24, 17,18,	18,	21,	18,	16,	24,	15,	18,	33,	21,	14	]	]	,												
['GAL',	6,	[24, 21,29,	31,	26,	18		]	]		,																	
['EPH',	6,	[23, 22,21,	32,	33,	24	]]	,																				
['PHP',	4,	[30, 30,21,	23	]	]	,																					
['COL',	4,	[29, 23,25,	18	]	]	,																					
['1TH',	5,	[10, 20,13,	18,	28	]	]		,																			
['2TH', 3,	[12, 17,18	]]		,																						
['1TI',	6,	[20, 15,16,	16,	25,	21	]],																					
['2TI',	4,	[18, 26,17,	22,	]	]	,																					
['TIT',	3,	[16, 15,15,	]]	,																							
['PHM',	1,	[25]] 	,																									
['HEB',	13,	[14, 18,19,	16,	14,	20,	28,	13,	28,	39,	40,	29,	25	]]	,													
['JAS',	5,	[27, 26,18,	17,	20	]],																						
['1PE',	5,	[25, 25,22,	19,	14	]]	,																					
['2PE',	3,	[21, 22,18,	]]		,																					
['1JN',	5,	[10, 29,24,	21,	21	]]	,																					
['2JN',	1,	[13]]			,																							
['3JN',	1,	[14]]	,																								
['Jud',	1,	[25]]		,																								
['Rev',	22,	[20, 29,22,	11,	14,	17,	17,	13,	21,	11,	19,	17,	18,	20,	8	,21	,18	,24	,21	,15	,27	,21]]
]

class App extends Component{
  constructor(){
    super();
    this.state = {
        reference: "",
        content: "",
        copyright: ""
    }  
    this.updateContent = this.updateContent.bind(this);
    
}

updateContent(props){
  this.setState({content: props.content});    
  this.setState({reference: props.reference});
  this.setState({copyright: props.copyright});
}

generateRandomScripture(props){

  var index = Math.floor(Math.random() * BOOK_DB.length);
  const bookName = BOOK_DB[index][0];
  const chapter  = (Math.ceil(Math.random() * BOOK_DB[index][1])) ;
  const verse    = (Math.ceil(Math.random() * BOOK_DB[index][2][chapter-1]));
  console.log('get: ' + bookName + '.' + chapter.toString() + '.' + verse.toString())

  axios
  .get('https://api.scripture.api.bible/v1/bibles/55ec700d9e0d77ea-01/verses/' + bookName + '.' + chapter.toString() + '.' + verse.toString() + '?content-type=text&include-notes=false&include-titles=false&include-chapter-numbers=false&include-verse-numbers=false&include-verse-spans=false&use-org-id=false',{
      headers: {
          'api-key': process.env.REACT_APP_BIBLE_API_KEY
      }
  })
  .then((res) => {
     console.log(res.data)
     props.updateContent(res.data.data);   
  })
  .catch((error) =>{
      console.error(error);
  } )
}

componentDidMount(){
  {this.generateRandomScripture(this)};
}

  render(){
    return (
      <div className="App">
        <header className="App-header">
        <div>
               <h1 className="header-content ">{this.state.reference}</h1>
               
                <p>{this.state.content}</p>
                <p className='copyright'> {this.state.copyright}  </p>
            </div>
      
            <div>
        <button onClick={() => { this.generateRandomScripture(this)}
         }>
            Get New Testament Scripture
        </button>
    </div>
        </header>
      </div>
    );
  }
}

export default App;
