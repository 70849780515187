import React from "react";
//import  axios  from "axios";
//import Scripture from "./Scripture";
// function GetRandomScripture()
// {
//     axios
//     .get('https://api.scripture.api.bible/v1/bibles/55ec700d9e0d77ea-01/verses/JHN.3.16',{
//         headers: {
//             'api-key': 'c5794d6b3138b41d3928f670609e1fb7'
//         }
//     })
//     .then((res) => {
//         console.log(res.data)
//  //       Scripture.updateData(res.data);
//     })
//     .catch((error) =>{
//         console.error(error)
//     } )
// }

function GenerateButton(props){
    return (
    <div>
        <button onClick={() => { props.onGenerateRandomScripture()}
         }>
            Generate New Scripture
        </button>
    </div>
    )
}

export default GenerateButton